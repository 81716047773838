window.countUp = function(element) {
  //reset the text when begining the count
  element.textContent = "0";
  let interval = 2000;
  let start_value = 0;
  let end_value = parseFloat(element.dataset.value);
  // console.log(end_value + "Is integer? " + Number.isInteger(end_value));
  // end_value.toFixed(1);
  let duration = (interval / end_value);
  let counter = setInterval(function() {
    if((end_value - start_value < 1 && !Number.isInteger(end_value))){
      start_value = start_value + 0.1;
      duration = 10;
    }
    else if(end_value - start_value < 50) {
      start_value = start_value + 1;
    }
    else if(end_value - start_value < 1000){
      start_value = start_value + 5;
    }
    else {
      start_value = start_value + 50;
    }
    if(Number.isInteger(end_value)) {
      element.textContent = start_value.toFixed(0);
      element.textContent = start_value.toLocaleString();
    }
    else {
      element.textContent = start_value.toFixed(1);
      element.textContent = start_value.toLocaleString(undefined, {minimumFractionDigits: 1})
    }
    if( start_value.toFixed(1) == end_value) {
      clearInterval(counter);
    }
  }, duration);
}
